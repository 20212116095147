import React from 'react';
import { FiLogOut } from 'react-icons/fi';
import MochalesLogo from '../static/mochalesAIO.jpg';

export const Navbar = ({ onLogout }) => {
  return (
    <nav>
      <img className='navLogo' src={MochalesLogo} alt="MochalesLogo" />
      <h1>Ticket Manager</h1>
      <button onClick={onLogout}><FiLogOut /></button>
    </nav>
  );
};
