import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from "react-select";
import { SalesAnalytics } from './SalesAnalytics';

const storeOptions = [
    { value: "LiveFootballTickets", label: "LiveFootballTickets" },
    { value: "Ticombo", label: "Ticombo" },
    { value: "Viagogo", label: "Viagogo" },
  ];

export const SalesTable = () => {
    const [tickets, setTickets] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [selectedStores, setSelectedStores] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const API_BASE_URL =
        window.location.hostname === 'localhost'
            ? 'http://localhost:3000/api'
            : 'https://www.mochalesdev.com/api';

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');  // Redirige al login si no hay token
        } else {
            // Verificar si el token es válido
            axios.post(
                `${API_BASE_URL}/verifytoken`, 
                { token },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then(response => {
                if (response.status === 200) {
                    setIsAuthenticated(true);  // El token es válido
                }
            })
            .catch(err => {
                console.error("Token no válido o sesión expirada", err);
                localStorage.removeItem('token');  // Eliminar token en caso de error
                navigate('/');  // Redirigir al login si el token no es válido
            });
        }
    }, [API_BASE_URL, navigate]);

    useEffect(() => {
        if (isAuthenticated) {
            const token = localStorage.getItem('token');
            axios
                .post(
                    `${API_BASE_URL}/sales`,
                    { 
                        store: selectedStores.map(store => store.value),

                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                .then(response => setTickets(response.data))
                .catch(err => console.error(err));
        }
    }, [API_BASE_URL, isAuthenticated, selectedStores]);


    const filteredTickets = tickets.filter(ticket =>
        ticket.event.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="table-container">
            <SalesAnalytics selectedStore={selectedStores} />
            <div className="filter-container">
                <Select
                    isMulti
                    options={storeOptions}
                    value={selectedStores}
                    onChange={setSelectedStores}
                    placeholder="Stores"
                />
                <input
                    type="text"
                    placeholder="Search by Event Name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ marginLeft: 'auto', padding: '5px' }}
                />
            </div>
            <div className='results'>{filteredTickets.length} sales found</div>
            <table>
                <thead>
                    <tr>
                        <th>Market</th>
                        <th>Sale ID</th>
                        <th>Sale Date</th>
                        <th>Event Name</th>
                        <th>Date</th>
                        <th>Category</th>
                        <th>Section</th>
                        <th>Row</th>
                        <th>Seats</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredTickets.map((ticket) => (
                        <tr key={ticket._id}>
                            <td>{ticket.site}</td>
                            <td>{ticket.id}</td>
                            <td>{ticket.sale_date}</td>
                            <td>{ticket.event}</td>
                            <td>{ticket.date}</td>
                            <td>{ticket.category}</td>
                            <td>{ticket.section}</td>
                            <td>{ticket.row}</td>
                            <td>{ticket.seating}</td>
                            <td>{ticket.qty}</td>
                            <td>{ticket.price} £</td>
                            <td>{ticket.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};



