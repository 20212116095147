import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { FaRunning, FaSave, FaEdit, FaTimes } from "react-icons/fa";
import { TbHandStop } from "react-icons/tb";
import { ListingsAnalytics } from "./ListingsAnalytics";

const storeOptions = [
  { value: "LiveFootballTickets", label: "LiveFootballTickets" },
  { value: "Ticombo", label: "Ticombo" },
  { value: "Viagogo", label: "Viagogo" },
];

const priceFilledOptions = [
  { value: "YES", label: "Filled Prices" },
  { value: "NO", label: "Not Filled Prices" },
];

const statusOptions = [
  { value: "YES", label: "Active Listings" },
  { value: "NO", label: "Not Active Listings" },
];

const detailsOptions = [
    { value: "Lowest price", label: "Lowest Price" },
    { value: "Max price", label: "Max Price" },
    { value: "Freezed", label: "Freezed" },
    { value: "error", label: "Error" },
  ];  

export const ListingsTable = () => {
  const [tickets, setTickets] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [editingTicketId, setEditingTicketId] = useState(null);
  const [newMinPrice, setNewMinPrice] = useState("");
  const [newMaxPrice, setNewMaxPrice] = useState("");
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedPriceFilled, setSelectedPriceFilled] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedDetails, setSelectedDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const API_BASE_URL =
    window.location.hostname === "localhost"
      ? "http://localhost:3000/api"
      : "https://www.mochalesdev.com/api";

  useEffect(() => {
      const token = localStorage.getItem('token');
      if (!token) {
          navigate('/');  // Redirige al login si no hay token
      } else {
          // Verificar si el token es válido
          axios.post(
              `${API_BASE_URL}/verifytoken`, 
              { token },
              { headers: { Authorization: `Bearer ${token}` } }
          )
          .then(response => {
              if (response.status === 200) {
                  setIsAuthenticated(true);  // El token es válido
              }
          })
          .catch(err => {
              console.error("Token no válido o sesión expirada", err);
              localStorage.removeItem('token');  // Eliminar token en caso de error
              navigate('/');  // Redirigir al login si el token no es válido
          });
      }
  }, [API_BASE_URL, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem("token");
      axios
        .post(
          `${API_BASE_URL}/tickets`,
          {
            store: selectedStores.map((store) => store.value),
            priceFilled: selectedPriceFilled.map((price) => price.value),
            statusFilled: selectedStatuses.map((status) => status.value),
            detailsFilled: selectedDetails.map((detail) => detail.value),
            
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => setTickets(response.data))
        .catch((err) => console.error(err));
    }
  }, [
    API_BASE_URL,
    isAuthenticated,
    selectedPriceFilled,
    selectedStatuses,
    selectedStores,
    selectedDetails,
  ]);

  const handleEdit = (ticketId, minPrice, maxPrice) => {
    setEditingTicketId(ticketId);
    setNewMinPrice(minPrice);
    setNewMaxPrice(maxPrice);
  };

  const handleSave = (ticketId) => {
    const token = localStorage.getItem("token");
    axios
      .put(
        `${API_BASE_URL}/tickets`,
        {
          store: selectedStores.map((store) => store.value),
          ticketId,
          min_price: newMinPrice,
          max_price: newMaxPrice,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(() => {
        setTickets(
          tickets.map((ticket) =>
            ticket._id === ticketId
              ? { ...ticket, min_price: newMinPrice, max_price: newMaxPrice }
              : ticket
          )
        );
        setEditingTicketId(null);
      })
      .catch((err) => console.error(err));
  };

  const handleCancel = () => {
    setEditingTicketId(null);
    setNewMinPrice("");
    setNewMaxPrice("");
  };

  const getStatusColor = (status) => {
    if (status === "Lowest price") {
      return "status-green";
    } else if (status === "Max price") {
      return "status-yellow";
    } else if (status === "Freezed") {
      return "status-blue";
    } else {
      return "status-red";
    }
  };

  const getStatusValue = (ticket) => {
    if (ticket.active === "YES") {
      if (!ticket?.status) {
        return "Error";
      }
      return ticket.status;
    } else {
      return "Stopped";
    }
  };

  const filteredTickets = tickets.filter((ticket) =>
    ticket.event.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="table-container">
      <ListingsAnalytics selectedStore={selectedStores.map((store) => store.value)} />
      <div className="filter-container">
        <Select
          isMulti
          options={storeOptions}
          value={selectedStores}
          onChange={setSelectedStores}
          placeholder="Stores"
        />
        <Select
          isMulti
          options={priceFilledOptions}
          value={selectedPriceFilled}
          onChange={setSelectedPriceFilled}
          placeholder="Price Filled Status"
        />
        <Select
          isMulti
          options={statusOptions}
          value={selectedStatuses}
          onChange={setSelectedStatuses}
          placeholder="Status"
        />
        <Select
          isMulti
          options={detailsOptions}
          value={selectedDetails}
          onChange={setSelectedDetails}
          placeholder="Details"
        />
        <input
          type="text"
          placeholder="Search by Event Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginLeft: "auto", padding: "5px" }}
        />
      </div>
      <div className="results">{filteredTickets.length} listings found</div>
      <table>
        <thead>
          <tr>
            <th>Market</th>
            <th>Event Name</th>
            <th>Date</th>
            <th>Category</th>
            <th>Section</th>
            <th>Row</th>
            <th>Seats</th>
            <th>Quantity</th>
            <th>Status</th>
            <th>Detailed</th>
            <th>Price</th>
            <th>Minimum Price</th>
            <th>Maximum Price</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {filteredTickets.map((ticket) => (
            <tr key={ticket._id}>
              <td>{ticket.site}</td>
              <td>{ticket.event}</td>
              <td>{ticket.date}</td>
              <td>{ticket.category}</td>
              <td>{ticket.section}</td>
              <td>{ticket.row}</td>
              <td>{ticket.seating}</td>
              <td>{ticket.qty}</td>
              <td className={ticket.active === "YES" ? "running" : "stopped"}>
                {ticket.active === "YES" ? <FaRunning /> : <TbHandStop />}
              </td>
              <td className={getStatusColor(ticket.status)}>{getStatusValue(ticket)}</td>
              <td>{ticket.selling_price} £</td>
              <td>
                {editingTicketId === ticket._id ? (
                  <input
                    type="number"
                    value={newMinPrice}
                    onChange={(e) => setNewMinPrice(e.target.value)}
                  />
                ) : (
                  ticket.min_price
                )} £
              </td>
              <td>
                {editingTicketId === ticket._id ? (
                  <input
                    type="number"
                    value={newMaxPrice}
                    onChange={(e) => setNewMaxPrice(e.target.value)}
                  />
                ) : (
                  ticket.max_price
                )} £
              </td>
              <td>
                {editingTicketId === ticket._id ? (
                  <>
                    <button
                      className="save-button"
                      onClick={() => handleSave(ticket._id)}
                    >
                      <FaSave />
                    </button>
                    <button className="cancel-button" onClick={handleCancel}>
                      <FaTimes />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="edit-button"
                      onClick={() =>
                        handleEdit(ticket._id, ticket.min_price, ticket.max_price)
                      }
                    >
                      <FaEdit />
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
