import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const SalesAnalytics = ({ selectedStore }) => {
    const [analytics, setAnalytics] = useState({
        day: { count: 0, revenew: 0 },
        week: { count: 0, revenew: 0 },
        month: { count: 0, revenew: 0 },
        year: { count: 0, revenew: 0 },
        allTime: { count: 0, revenew: 0 },
    });
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    const API_BASE_URL =
        window.location.hostname === 'localhost'
            ? 'http://localhost:3000/api'
            : 'https://www.mochalesdev.com/api';

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');  // Redirige al login si no hay token
        } else {
            // Verificar si el token es válido
            axios.post(
                `${API_BASE_URL}/verifytoken`, 
                { token },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then(response => {
                if (response.status === 200) {
                    setIsAuthenticated(true);  // El token es válido
                }
            })
            .catch(err => {
                console.error("Token no válido o sesión expirada", err);
                localStorage.removeItem('token');  // Eliminar token en caso de error
                navigate('/');  // Redirigir al login si el token no es válido
            });
        }
    }, [API_BASE_URL, navigate]);

    useEffect(() => {
        if (isAuthenticated) {
            const token = localStorage.getItem('token');
            axios
                .post(
                    `${API_BASE_URL}/salesanalytics`,
                    {
                        store: selectedStore,
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                .then(response => {
                    const data = response.data;
                    setAnalytics( data );
                })
                .catch(err => console.error('Error fetching analytics:', err));
        }
    }, [API_BASE_URL, isAuthenticated, selectedStore]);

    return (
        <div className="analytics-detailed">
            <div className="analytic-detailed">
                <p>DAY</p>
                <v>{analytics.day.count}</v>
                <v2>{analytics.day.revenew} £</v2>
            </div>
            <div className="analytic-detailed">
                <p>WEEK</p>
                <v>{analytics.week.count}</v>
                <v2>{analytics.week.revenew} £</v2>
            </div>
            <div className="analytic-detailed">
                <p>MONTH</p>
                <v>{analytics.month.count}</v>
                <v2>{analytics.month.revenew} £</v2>
            </div>
            <div className="analytic-detailed">
                <p>YEAR</p>
                <v>{analytics.year.count}</v>
                <v2>{analytics.year.revenew} £</v2>
            </div>
            <div className="analytic-detailed">
                <p>ALL TIME</p>
                <v>{analytics.allTime.count}</v>
                <v2>{analytics.allTime.revenew} £</v2>
            </div>
        </div>
    );
};


