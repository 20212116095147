import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiLogIn } from 'react-icons/fi';
import axios from 'axios'; // Asegúrate de tener axios instalado

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  
  const handleLogin = async () => {
    const API_BASE_URL =
    window.location.hostname === "localhost"
      ? "http://localhost:3000/api"
      : "https://www.mochalesdev.com/api";


    try {
      const response = await axios.post( `${API_BASE_URL}/login`, {
        email: email,
        password: password,
      });
      
      // Si la API devuelve un redirect, guardamos el token y redirigimos
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        setError('');
        navigate(response.data.redirect); // Redirige según lo que la API devuelve
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError('Invalid Credentials');
      } else {
        setError('An error occurred. Please try again later.');
      }
    }
  };

  return (
    <div className="login">
      <h2>Login as a client</h2>
      <input
        type="email"
        placeholder="Client Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Client Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}><FiLogIn /></button>
      {error && <p>{error}</p>}
    </div>
  );
};
