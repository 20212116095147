import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar } from './Navbar';
import { ListingsTable } from './ListingsTable';
import { SalesTable } from './SalesTable';
import axios from 'axios';

export const JusttikitDashboard = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [view, setView] = useState('Tickets'); // Nuevo estado para controlar la vista activa
    const navigate = useNavigate();

    const API_BASE_URL =
    window.location.hostname === "localhost"
      ? "http://localhost:3000/api"
      : "https://www.mochalesdev.com/api";

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');  // Redirige al login si no hay token
        } else {
            // Verificar si el token es válido
            axios.post(
                `${API_BASE_URL}/verifytoken`, 
                { token },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then(response => {
                if (response.status === 200) {
                    setIsAuthenticated(true);  // El token es válido
                }
            })
            .catch(err => {
                console.error("Token no válido o sesión expirada", err);
                localStorage.removeItem('token');  // Eliminar token en caso de error
                navigate('/');  // Redirigir al login si el token no es válido
            });
        }
    }, [API_BASE_URL, navigate]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        navigate('/');
    };


    const renderView = () => {
        switch (view) {
            case 'Tickets':
                return <ListingsTable />;
            case 'Sales':
                return <SalesTable />;
            default:
                return null;
        }
    };

    return (
        <div className="App">
            {isAuthenticated && (
                <div>
                    <Navbar onLogout={handleLogout} />
                    <div className="subNavbar-selector">
                        <button
                            onClick={() => setView('Tickets')}
                            className={view === 'Tickets' ? 'active' : ''}
                        >
                            Tickets
                        </button>
                        <button
                            onClick={() => setView('Sales')}
                            className={view === 'Sales' ? 'active' : ''}
                        >
                            Sales
                        </button>
                    </div>
                    <div className="view-container">{renderView()}</div>

                </div>
            )}
        </div>
    );
};
