import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const ListingsAnalytics = ({ selectedStore }) => {
    const [analytics, setAnalytics] = useState({
        active: { count: 0, percentage: 0 },
        paused: { count: 0, percentage: 0 },
        lowestPrice: { count: 0, percentage: 0 },
        maxPrice: { count: 0, percentage: 0 },
        freezed: { count: 0, percentage: 0 },
        // priceNotFilled: { count: 0, percentage: 0 },
        error: { count: 0, percentage: 0 }

    });
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    const API_BASE_URL =
        window.location.hostname === 'localhost'
            ? 'http://localhost:3000/api'
            : 'https://www.mochalesdev.com/api';

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');  // Redirige al login si no hay token
        } else {
            // Verificar si el token es válido
            axios.post(
                `${API_BASE_URL}/verifytoken`, 
                { token },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then(response => {
                if (response.status === 200) {
                    setIsAuthenticated(true);  // El token es válido
                }
            })
            .catch(err => {
                console.error("Token no válido o sesión expirada", err);
                localStorage.removeItem('token');  // Eliminar token en caso de error
                navigate('/');  // Redirigir al login si el token no es válido
            });
        }
    }, [API_BASE_URL, navigate]);

    useEffect(() => {
        if (isAuthenticated) {
            const token = localStorage.getItem('token');
            axios
                .post(
                    `${API_BASE_URL}/ticketsanalytics`,
                    {
                        store: selectedStore,
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                .then(response => {
                    const data = response.data;
                    setAnalytics( data );
                })
                .catch(err => console.error('Error fetching analytics:', err));
        }
    }, [API_BASE_URL, isAuthenticated, selectedStore]);

    return (
        <div className="analytics-detailed">
            <div className="analytic-detailed">
                <p>ACTIVE</p>
                <v>{analytics.active.count}</v>
                <v2>{analytics.active.percentage}%</v2>
            </div>
            <div className="analytic-detailed">
                <p>PAUSED</p>
                <v>{analytics.paused.count}</v>
                <v2>{analytics.paused.percentage}%</v2>
            </div>
            <div className="analytic-detailed">
                <p>LOWEST PRICE</p>
                <v>{analytics.lowestPrice.count}</v>
                <v2>{analytics.lowestPrice.percentage}%</v2>
            </div>
            <div className="analytic-detailed">
                <p>MAX PRICE</p>
                <v>{analytics.maxPrice.count}</v>
                <v2>{analytics.maxPrice.percentage}%</v2>
            </div>
            <div className="analytic-detailed">
                <p>FREEZED</p>
                <v>{analytics.freezed.count}</v>
                <v2>{analytics.freezed.percentage}%</v2>
            </div>
            {/* <div className="analytic-detailed">
                <p>PRICE NOT FILLED</p>
                <v>{analytics.priceNotFilled.count}</v>
                <v2>{analytics.priceNotFilled.percentage}%</v2>
            </div> */}
            <div className="analytic-detailed">
                <p>ERROR</p>
                <v>{analytics.error.count}</v>
                <v2>{analytics.error.percentage}%</v2>
            </div>
            
        </div>
    );
};


